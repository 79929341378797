<template>
  <div>
    <the-client-layout-header
      :headline="headline"
      @confirmChanges="onConfirmChanges"
    />

    <v-card flat height="auto" class="d-flex align-stretch">
      <v-carousel
        v-model="caro"
        light
        height="auto"
        :show-arrows="false"
        hide-delimiter-background
        hide-delimiters
      >
        <v-carousel-item
          key="1"
          reverse-transition="fade-transition"
          transition="fade-transition"
          value="financialplan"
          class="mb-12"
          ><client-projection-financial-plan
            :items="planItems.items"
            :goalItems="planItems.goals"
            :adviceItems="planItems.advice"
            @unSavedChanges="saveConfirmDialog(caro)"
            @presetFinancialPlanItem="onPresetFinancialPlanItem"
          />
          <dialog-save-discard-changes ref="dialogChanges" />
        </v-carousel-item>

        <v-carousel-item
          key="2"
          reverse-transition="fade-transition"
          transition="fade-transition"
          value="assets"
          class="mb-12"
        >
          <v-row class="mx-12">
            <v-col cols="12" sm="4" md="3" lg="3"
              ><client-projection-assets-nav />
            </v-col>

            <v-col cols="12" sm="8" md="9" lg="9"
              ><v-container>
                <v-row class="flex-child">
                  <v-col class="d-flex" cols="12" md="12">
                    <v-row class="ma-n3">
                      <v-col cols="12">
                        <client-projection-assets-chart />
                      </v-col>
                      <v-col cols="12">
                        <v-sheet
                          class="d-flex"
                          color="grey lighten-3"
                          height="350"
                          ><v-card
                            ><v-card-text
                              >Spreadsheet: Numbers over time (Valuation,
                              NetWorth, Liability) for either a single Asset or
                              combination of Assets (if combination, every asset
                              gets a separate row with totals on the bottom of
                              the page)...</v-card-text
                            ></v-card
                          >
                        </v-sheet>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
          <dialog-save-discard-changes ref="dialogChanges" />
        </v-carousel-item>
      </v-carousel>
    </v-card>
  </div>
</template>

<script>
import store from '@/vuex/store'
import { mapState } from 'vuex'
import { authComputed } from '@/vuex/helpers.js'
import TheClientLayoutHeader from '@/components/TheClientLayoutHeader.vue'
import ClientProjectionFinancialPlan from '@/components/ClientProjectionFinancialPlan.vue'
import ClientProjectionAssetsNav from '@/components/ClientProjectionAssetsNav.vue'
import ClientProjectionAssetsChart from '@/components/ClientProjectionAssetsChart.vue'
import DialogSaveDiscardChanges from '@/components/dialogs/DialogSaveDiscardChanges.vue'

export default {
  name: 'clientProjection',

  beforeRouteEnter(routeTo, routeFrom, next) {
    // making sure to move slider to "PROJECTION"(tab = 4), when navigating to this page
    store.commit('SET_CLIENT_LAYOUT_TAB', 4)
    store.commit('SET_CLIENT_PROJECTION_CARO', routeTo.params.tabs)
    next()
  },

  // making sure to save, when navigating away to different secondary navigation
  beforeRouteUpdate(routeTo, routeFrom, next) {
    if (this.system.dataChanged === true) {
      console.log('data has changed, ask to confirm or decline')
      this.saveConfirmDialog(routeFrom.params.tabs).then(() => {
        next()
      })
    } else next()
  },

  // making sure to save, when navigating away to different primary navigation
  beforeRouteLeave(routeTo, routeFrom, next) {
    if (this.system.dataChanged === true) {
      console.log('data has changed, ask to confirm or decline')
      this.saveConfirmDialog(routeFrom.params.tabs).then(() => {
        this.$store.commit('SET_DATA_CHANGED', false)
        next()
      })
    } else next()
  },

  components: {
    TheClientLayoutHeader,
    DialogSaveDiscardChanges,
    ClientProjectionFinancialPlan,
    ClientProjectionAssetsNav,
    ClientProjectionAssetsChart
  },

  data() {
    return {
      tabs: null,
      projection: {
        financialplan: { id: 1, name: 'Financial Plan' },
        assets: { id: 2, name: 'Assets' }
      },
      goalItem: {}
      // adviceItems: [
      //   {
      //     id: 12360979,
      //     rank: 0,
      //     primaryText: 'Total Extra Savings',
      //     secondaryText: 'save additional 500 NZD/month',
      //     icon: 'mdi-cash-refund'
      //   },
      //   {
      //     id: 4759394,
      //     rank: 0,
      //     primaryText: 'Sell Warehouse Hamilton',
      //     secondaryText: 'Receive 800,000 NZD in 2029',
      //     icon: 'mdi-cash-plus'
      //   },
      //   {
      //     id: 37485969,
      //     rank: 0,
      //     primaryText: 'Downsize Own House',
      //     secondaryText: 'Receive 200,000 NZD in 2045',
      //     icon: 'mdi-cash-plus'
      //   },

      //   {
      //     id: 475865969,
      //     rank: 0,
      //     primaryText: 'Sell Own House',
      //     secondaryText: 'Receive 500,000 NZD in 2055',
      //     icon: 'mdi-cash-plus'
      //   }
      // ]
    }
  },

  methods: {
    // handler function for DialogSaveDiscardChanges component
    async saveConfirmDialog(pageFrom) {
      const confirmation = await this.$refs.dialogChanges.show(
        this.projection[pageFrom]
      )
      if (confirmation === 'save') {
        console.log('Confirmation - save changes')
        this.onConfirmChanges()
      }
      if (confirmation === 'discard') {
        console.log('Cancel - discard changes')
        this.onPresetFinancialPlanItem()
      }
    },

    onPresetFinancialPlanItem() {
      // preset finacialPlan and parameters
      this.$store.commit('PRESET_FINANCIAL_PLAN', this.planItems.items)
      // set selectedItemId in state by overwriting with (wanted) newItemId
      this.$store.commit(
        'SET_FINANCIAL_PLAN_SELECTED_ITEM_ID',
        this.clientProjection.newItemId
      )
      // set dataChanged flag back to false
      this.$store.commit('SET_DATA_CHANGED', false)
      // reset retirement validation to have a fresh start after revisiting the component
    },

    onConfirmChanges() {
      // create fresh copy of current goal item
      this.goalItem = Object.assign(
        {},
        this.planItems.items[this.clientProjection.selectedItemId]
      )
      // copy or merge new parameters into goal item
      Object.assign(
        this.goalItem.proposedData,
        this.clientProjection.parameters
      )
      // update State and DDB, once finished, reset goalItem
      this.$store.dispatch('updateGoal', this.goalItem).then(() => {
        console.log('new Parmeters saved', this.goalItem)
        this.goalItem = {}
        this.onPresetFinancialPlanItem()
      })
    }
  },

  computed: {
    headline() {
      return 'Projection / ' + this.page.name
    },

    caro: {
      get() {
        return this.system.caroClientProjection
      },
      set(projection) {
        this.$store.commit('SET_CLIENT_PROJECTION_CARO', projection)
        this.$router.push({
          name: 'clientProjection',
          params: { tabs: projection }
        })
      }
    },

    page() {
      let page = this.projection[this.caro]
      return page
    },

    planItems() {
      // process Goals
      let predefinedGoalItems = []
      let individualGoalItems = []
      let rank = 0
      let planItems = {}
      Object.entries(this.clientGoals).forEach(([, goal]) => {
        // add retirement goal (if any) on first position
        if (goal.id == 2) {
          predefinedGoalItems.push(goal)
        }
        // remove predefined emergency-fund item if not in use (value = 0)
        // or if in use, add on second position
        else if (goal.type == 'Emergency-Fund') {
          if (goal.currentData.value != 0) {
            predefinedGoalItems.push(goal)
          }
        }
        // add individual Goals (if any)
        else {
          individualGoalItems.push(goal)
        }
      })
      // sort individual items acending according to targetYear
      individualGoalItems.sort(function(a, b) {
        return a.targetYear - b.targetYear
      })
      console.log('predefined goal items = ', predefinedGoalItems)
      console.log('individual goal items = ', individualGoalItems)
      // combine arrays
      let goals = predefinedGoalItems.concat(individualGoalItems)
      // rank items
      Object.entries(goals).forEach(([, item]) => {
        item.rank = rank
        rank++
      })

      // process Advice
      let predefinedAdviceItems = []
      let individualAdviceItems = []
      Object.entries(this.clientAdvice).forEach(([, advice]) => {
        // add predefined extra savings advice on first position
        if (advice.id == 50) {
          predefinedAdviceItems.push(advice)
        }
        // add individual Advice (if any)
        else {
          individualAdviceItems.push(advice)
        }
      })
      // sort individual items acending according to targetYear
      individualAdviceItems.sort(function(a, b) {
        return a.targetYear - b.targetYear
      })
      console.log('predefined advice items = ', predefinedAdviceItems)
      console.log('individual advice items = ', individualAdviceItems)
      // combine arrays
      let advice = predefinedAdviceItems.concat(individualAdviceItems)
      Object.entries(advice).forEach(([, item]) => {
        item.rank = rank
        rank++
      })

      planItems.items = goals.concat(advice)
      planItems.goals = goals
      planItems.advice = advice

      return planItems
    },

    ...mapState(['system', 'clientProjection']),

    ...authComputed
  }
}
</script>

<style lang="scss" scoped></style>
