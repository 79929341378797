import store from '@/vuex/store'

const retirementLifeExpectancy = () => {
  return {
    headline: 'Life Expectancy Assumption',
    // post as many paragraph items in array as you wish
    paragraph: [
      'Each clients’ life expectancy  is automatically populated taking into consideration their year of birth, current age and gender. This is done by using Stats NZ’s life expectancy calculator, assuming medium death rates.',
      'Life expectancy indicates the average length of life. It is an estimate and does not indicate how long your client will actually live.',
      'The life expectancy is automatically updated as your client ages and can be manually adjusted anytime.'
    ],
    source:
      'Stats NZ, complete cohort life tables 1876–2017 (updated March 2019) and national population projections 2016(base)–2068, mortality assumptions (published October 2016).',
    url: 'https://www.stats.govt.nz/tools/how-long-will-i-live'
  }
}

const retirementExpenseFixed = () => {
  return {
    headline: 'Retirement Expense Info',
    // post as many paragraph items in array as you wish
    paragraph: [
      'In order to maintain the purchasing power of ' +
        store.state.clientProjection.parameters.retirementExpenseValue.toLocaleString(
          undefined,
          {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }
        ) +
        ' ' +
        store.getters.currencyApp +
        ' under an inflation rate of ' +
        store.state.clientLedger.ledger.conditions.inflationRate +
        ' %, you will need ' +
        ' ???TBD??? ' +
        store.getters.currencyApp +
        ' at the age of ' +
        store.state.clientProjection.parameters.retirementAgePerson1 +
        '.'
    ],
    source: '',
    url: ''
  }
}

const extraSavings = () => {
  return {
    headline: 'Extra Savings',
    // post as many paragraph items in array as you wish
    paragraph: [
      'The value in brackets is representing the currently available monthly Surplus (Income - Expenses).',
      'You can use parts or all of this Surplus for Extra Savings in order to help you to achive your goal.'
    ],
    source: '',
    url: ''
  }
}

export default {
  getRetirementLifeExpectancyInfo() {
    return retirementLifeExpectancy()
  },

  getRetirementExpenseFixedInfo() {
    return retirementExpenseFixed()
  },

  getExtraSavingsInfo() {
    return extraSavings()
  }
}
