<template>
  <div>
    <v-container fluid class="align-start px-0 mt-n3 d-flex flex-row">
      <v-card flat>
        <v-navigation-drawer
          v-model="drawer"
          :mini-variant.sync="mini"
          width="360"
          mini-variant-width="60"
          permanent
        >
          <v-list-item class="px-5">
            <v-app-bar-nav-icon v-if="!!mini" @click.stop="mini = !mini" />

            <v-list-item-title
              v-text="'Client Goals'"
              class="primary--text text-uppercase subtitle-2 ml-6"
            ></v-list-item-title>
            <v-btn icon @click.stop="mini = !mini">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </v-list-item>

          <v-divider></v-divider>

          <v-list dense rounded>
            <v-list-item-group
              v-model="newItemId"
              mandatory
              active-class="#bdbdbd--background"
            >
              <v-card min-height="360" flat>
                <v-list-item v-for="(item, i) in goalItems" :key="i">
                  <v-list-item-icon
                    ><v-icon color="success" v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="item.headline"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                      v-text="item.currentData.description"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>

              <v-card min-height="343" flat class="pt-4">
                <v-list-item v-for="item in adviceItems" :key="item.id">
                  <v-list-item-icon
                    ><v-icon color="success" v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="item.headline"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                      v-text="item.proposedData.description"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
      </v-card>

      <v-row class="flex-child mx-4">
        <v-col class="d-flex" cols="12" md="12">
          <v-row>
            <v-col cols="12" v-if="items[selectedItemId].type == 'Retirement'"
              ><v-card flat height="365">
                <plan-retirement-general-chart />
              </v-card>
            </v-col>
            <v-col cols="12" v-else><v-card flat height="365"></v-card></v-col>

            <v-col cols="12">
              <v-card flat class="mt-n6">
                <v-row>
                  <v-col cols="10">
                    <v-tabs
                      v-model="detailsTab"
                      color="primary"
                      slider-color="success"
                      center-active
                      transition="scroll-y-transition"
                    >
                      <v-tab
                        v-for="i in 4"
                        :key="i"
                        :href="`#tab-${i}`"
                        class="primary--text subtitle-2 mt-4"
                      >
                        {{ detailsTabItems[i - 1] }}
                      </v-tab>
                    </v-tabs>
                  </v-col>

                  <v-col cols="2" class="text-right"> </v-col>

                  <v-col cols="12" class="mt-n4">
                    <v-toolbar
                      flat
                      color="clientLayout"
                      height="30"
                      class="caption white--text"
                    >
                      <v-row justify="space-between"
                        ><v-col
                          cols="6"
                          sm="6"
                          md="6"
                          lg="6"
                          xl="6"
                          class="text-left"
                          ><span
                            v-text="items[selectedItemId].headline"
                            class="subtitle-1 font-weight-normal"
                          ></span>
                        </v-col>

                        <v-col
                          v-if="detailsTab == 'tab-2'"
                          cols="6"
                          sm="6"
                          md="6"
                          lg="6"
                          xl="6"
                          class="text-right"
                        >
                          <span
                            v-text="'Contribution (%) '"
                            class="subtitle-1 font-weight-normal"
                          ></span>
                          <span
                            v-text="'/ Year'"
                            class="subtitle-1 font-weight-normal mr-9"
                          ></span>
                        </v-col>
                        <v-col
                          v-else
                          cols="6"
                          sm="6"
                          md="6"
                          lg="6"
                          xl="6"
                          class="text-right"
                        >
                        </v-col>
                      </v-row>
                    </v-toolbar>

                    <v-row justify="space-between"
                      ><v-col
                        cols="2"
                        sm="4"
                        md="3"
                        lg="2"
                        xl="2"
                        class="text-center mt-4"
                      >
                        <chart-radial-bar-rate :series="[65]" />
                      </v-col>

                      <v-col cols="10" sm="8" md="9" lg="10" xl="10">
                        <v-tabs-items v-model="detailsTab">
                          <v-tab-item value="tab-1" key="1" class="mt-2"
                            ><div
                              v-if="items[selectedItemId].type == 'Retirement'"
                            >
                              <plan-retirement-general
                                :selectedItem="items[selectedItemId]"
                                @showHideSaveButton="onShowHideSaveButton"
                              />
                            </div>
                          </v-tab-item>

                          <v-tab-item value="tab-2" key="2" class="mt-2"
                            ><div
                              v-if="items[selectedItemId].type == 'Retirement'"
                            >
                              <plan-retirement-funding
                                :selectedItem="items[selectedItemId]"
                                @showHideSaveButton="onShowHideSaveButton"
                              />
                            </div>
                          </v-tab-item>

                          <v-tab-item value="tab-3" key="3" class="mt-5"
                            ><plan-advice
                              :selectedItem="items[selectedItemId]"
                              @showHideSaveButton="onShowHideSaveButton"
                            />
                          </v-tab-item>

                          <v-tab-item value="tab-4" key="4">
                            <plan-comment
                              :selectedItem="items[selectedItemId]"
                              @showHideSaveButton="onShowHideSaveButton"
                            />
                          </v-tab-item>
                        </v-tabs-items>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { authComputed } from '@/vuex/helpers.js'
import ChartRadialBarRate from '@/components/charts/ChartRadialBarRate.vue'
import PlanRetirementGeneralChart from '@/components/plan/PlanRetirementGeneralChart.vue'
import PlanRetirementGeneral from '@/components/plan/PlanRetirementGeneral.vue'
import PlanRetirementFunding from '@/components/plan/PlanRetirementFunding.vue'
import PlanAdvice from '@/components/plan/PlanAdvice.vue'
import PlanComment from '@/components/plan/PlanComment.vue'

export default {
  name: 'clientProjectionFinancialPlan',

  props: {
    items: { type: Array },
    goalItems: { type: Array },
    adviceItems: { type: Array }
  },

  components: {
    ChartRadialBarRate,
    PlanRetirementGeneralChart,
    PlanRetirementGeneral,
    PlanRetirementFunding,
    PlanAdvice,
    PlanComment
  },

  data() {
    return {
      detailsTab: null,
      drawer: true,
      mini: false
    }
  },

  created() {
    // preset financialPlan items and parameters when navigate to page for the first time
    this.$emit('presetFinancialPlanItem')
  },

  watch: {
    // preset financialPlan items and parameters every time when switching between items (selectedItemId is changing)
    newItemId: 'itemSwitchHandler'
  },

  methods: {
    itemSwitchHandler() {
      // if changes have been made, open confirm dialog window
      if (this.dataChanged === true) {
        this.$emit('unSavedChanges')
      }
      // if no changes have been made, preset data for requested item
      else {
        console.log('Preset new Goal Item')
        this.$emit('presetFinancialPlanItem')
      }
    },

    onShowHideSaveButton(dataChanged) {
      this.dataChanged = dataChanged
    }
  },

  computed: {
    series() {
      return [65, 0, 50, 20, 100, 75, 5, 9]
    },

    selectedItemId: {
      get() {
        return this.clientProjection.selectedItemId
      },
      set(id) {
        this.$store.commit('SET_FINANCIAL_PLAN_SELECTED_ITEM_ID', id)
      }
    },

    newItemId: {
      get() {
        return this.clientProjection.newItemId
      },
      set(id) {
        this.$store.commit('SET_FINANCIAL_PLAN_NEW_ITEM_ID', id)
      }
    },

    dataChanged: {
      get() {
        return this.system.dataChanged
      },
      set(dataChanged) {
        this.$store.commit('SET_DATA_CHANGED', dataChanged)
      }
    },

    detailsTabItems() {
      let items = ['General', 'Funding (2)', 'Advice (1)', 'Comment']
      return items
    },

    ...mapState(['system', 'clientProjection']),

    ...authComputed
  }
}
</script>

<style lang="scss" scoped></style>
