<template>
  <div>
    <v-card flat>
      <v-row>
        <v-col cols="2" sm="2" md="2" lg="1" xl="1" class="text-left"
          ><v-checkbox
            v-model="check"
            color="green"
            class="subtitle-1 primary--text font-weight-normal mt-n2 ml-1"
            prepend-icon="mdi-piggy-bank"
          >
          </v-checkbox>
        </v-col>
        <v-col cols="9" sm="9" md="9" lg="10" xl="10" class="text-left mt-n1">
          <v-span
            v-if="!!check"
            v-text="
              'Save an additional 1500 NZD/month in a dedicated WealthView account'
            "
            class="subtitle-1 grey--text font-weight-light text-decoration-line-through"
          >
          </v-span>
          <v-span
            v-else
            v-text="
              'Save an additional 1500 NZD/month in a dedicated WealthView account'
            "
            class="subtitle-1 primary--text"
          >
          </v-span>
        </v-col>
        <v-col cols="1" sm="1" md="1" lg="1" xl="1" class="text-right">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }"
              ><v-icon
                @click="deleteConfirm(dataItem)"
                v-bind="attrs"
                v-on="on"
                color="success"
                class="mt-n1 mr-4"
              >
                mdi-delete
              </v-icon></template
            >
            <span>Delete</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-divider color="clientLayout" class="mt-n3"></v-divider>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { authComputed } from '@/vuex/helpers.js'

export default {
  name: 'InputAdvice',

  props: { parameters: { type: Object } },

  data() {
    return {
      check: false
    }
  },

  methods: {
    deleteConfirm() {}
  },

  computed: {
    item() {
      return { icon: 'mdi-piggy-bank', iconColor: 'error' }
    },

    ...mapState(['clientLedger', 'clientProjection', 'client']),

    ...authComputed
  }
}
</script>

<style lang="scss" scoped></style>
