<template>
  <div>
    <v-row>
      <v-col cols="12">
        <template>
          <v-card flat class="mx-auto mt-n2 mb-n4">
            <v-card-text>
              <v-row class="mt-n6" justify="space-between">
                <v-col cols="12" class="text-left">
                  <v-toolbar height="30" flat>
                    <span class="overline primary--text ml-n4 mr-n3"
                      >EXTRA SAVINGS</span
                    >
                    <dialog-info-text :text="infoTextExtraSavings" />
                  </v-toolbar>
                </v-col>
              </v-row>

              <v-row class="mt-n4" justify="space-between">
                <v-col cols="4" sm="12" md="2" lg="4" xl="4" class="text-center"
                  ><v-slider
                    v-model="extraSavings"
                    color="error"
                    track-color="grey"
                    min="0"
                    max="1500"
                    step="50"
                  >
                    <template v-slot:prepend>
                      <v-icon color="error" @click="decrementExtraSavings">
                        mdi-minus
                      </v-icon>
                    </template>

                    <template v-slot:append>
                      <v-icon color="error" @click="incrementExtraSavings">
                        mdi-plus
                      </v-icon>
                    </template>
                  </v-slider></v-col
                >

                <v-col cols="4" sm="12" md="5" lg="4" xl="4" class="text-left"
                  ><span class="subheading pr-1" v-text="'(5,471)'"></span>
                  <span
                    class="headline primary--text"
                    v-text="
                      extraSavings.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      })
                    "
                  ></span
                  ><span class="subheading"> {{ currencyApp }}/month</span>
                </v-col>

                <v-col cols="4" sm="12" md="5" lg="4" xl="4" class="text-right">
                  <v-row
                    ><v-col cols="12">
                      <span
                        class="headline primary--text mr-1"
                        v-text="
                          extraSavings.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                          })
                        "
                      ></span>

                      <span v-if="extraSavings < 10" class="subheading pr-6"
                        >{{ currencyApp }}
                      </span>
                      <span
                        v-else-if="extraSavings >= 10 && extraSavings < 100"
                        class="subheading pr-3"
                        >{{ currencyApp }}
                      </span>
                      <span v-else class="subheading">{{ currencyApp }} </span>

                      <span
                        class="headline primary--text"
                        v-text="
                          extraSavings.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                          })
                        "
                      ></span>
                      <span class="subheading" v-text="' % / '"></span>
                      <span class="subheading pr-2" v-text="'2024'"></span>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }"
                          ><v-icon
                            @click="deleteConfirm(dataItem)"
                            v-bind="attrs"
                            v-on="on"
                            color="success"
                            class="mb-2"
                          >
                            mdi-delete
                          </v-icon></template
                        >
                        <span>Delete</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { authComputed } from '@/vuex/helpers.js'
import InfoTextService from '@/services/InfoTextService.js'
import DialogInfoText from '@/components/dialogs/DialogInfoText.vue'

export default {
  name: 'InputExtraSavings',

  props: { parameters: { type: Object } },

  components: {
    DialogInfoText
  },

  data() {
    return {
      extraSavings: 0
    }
  },

  methods: {
    decrementExtraSavings() {
      this.extraSavings -= 50
    },
    incrementExtraSavings() {
      this.extraSavings += 50
    },
    deleteConfirm() {}
  },

  computed: {
    infoTextExtraSavings() {
      return InfoTextService.getExtraSavingsInfo()
    },

    extraSavingsMaxMonth() {
      return this.clientExtraSavingsMaxSum / 12 - this.extraSavings
    },

    ...mapState(['clientLedger', 'clientProjection', 'client']),

    ...authComputed
  }
}
</script>

<style lang="scss" scoped></style>
