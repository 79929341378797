<template>
  <div>
    <v-sheet class="d-flex" color="grey lighten-3" height="350"
      ><v-card
        ><v-card-text
          >Chart: Projection over time (display Valuation, NetWorth and
          Liability separately) for either a single Asset or any Asset
          combination of your choice ...</v-card-text
        ></v-card
      >
    </v-sheet>
  </div>
</template>

<script>
import { authComputed } from '@/vuex/helpers.js'
// import SystemService from '@/services/SystemService.js'
// import StaticDataService from '@/services/StaticDataService.js'

export default {
  name: 'clientProjectionAssetsChart',

  data() {
    return {}
  },

  methods: {},

  computed: {
    ...authComputed
  }
}
</script>

<style lang="scss" scoped></style>
