<template>
  <div>
    <v-row>
      <v-col cols="6">
        <template>
          <v-card flat class="mx-auto mt-n2">
            <v-card-text>
              <v-row
                v-if="selectedItem.currentData.retirementExpenseType == 'fixed'"
                class="mt-n6"
                justify="space-between"
                ><v-col cols="10" class="text-left">
                  <span class="overline primary--text font-weight-normal">
                    RETIREMENT EXPENSE P.A.
                  </span>
                </v-col>
                <v-col cols="2" class="text-right">
                  <dialog-info-text :text="infoTextRetirementExpenseFixed" />
                </v-col>
              </v-row>

              <v-row v-else>
                <v-col class="text-left">
                  <span class="overline primary--text font-weight-normal">
                    RETIREMENT EXPENSE P.A.
                  </span>
                  <span class="text-caption primary--text font-weight-normal">
                    (RATED)
                  </span>
                </v-col></v-row
              >

              <v-row
                v-if="selectedItem.currentData.retirementExpenseType == 'fixed'"
                class="mt-n6"
                justify="space-between"
              >
                <v-col cols="6" sm="12" md="12" lg="5" xl="6" class="text-left"
                  ><v-slider
                    v-model="parameters.retirementExpenseValue"
                    color="success"
                    track-color="grey"
                    min="0"
                    max="500000"
                    step="5000"
                    class="mb-n5"
                  >
                    <template v-slot:prepend>
                      <v-icon color="success" @click="decrementRetExp">
                        mdi-minus
                      </v-icon>
                    </template>

                    <template v-slot:append>
                      <v-icon color="success" @click="incrementRetExp">
                        mdi-plus
                      </v-icon>
                    </template>
                  </v-slider></v-col
                >
                <v-col
                  cols="6"
                  sm="12"
                  md="12"
                  lg="7"
                  xl="6"
                  class="text-right"
                >
                  <span
                    v-if="
                      selectedItem.currentData.retirementExpenseValue !==
                        parameters.retirementExpenseValue
                    "
                    class="subheading font-weight-light mr-1"
                    v-text="
                      '(' +
                        selectedItem.currentData.retirementExpenseValue.toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                          }
                        ) +
                        ')'
                    "
                  ></span>
                  <span
                    class="headline primary--text mr-1"
                    v-text="
                      parameters.retirementExpenseValue.toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0
                        }
                      )
                    "
                  ></span>
                  <span class="subheading font-weight-light">{{
                    currencyApp
                  }}</span>
                </v-col>
              </v-row>

              <v-row v-else class="mt-n8" justify="space-between">
                <v-col cols="8" sm="12" md="12" lg="7" class="text-left"
                  ><v-slider
                    v-model="parameters.retirementExpenseRate"
                    color="success"
                    track-color="grey"
                    min="50"
                    max="150"
                    step="5"
                    class="mt-3 mb-n8"
                  >
                    <template v-slot:prepend>
                      <v-icon color="success" @click="decrementRetExpRate">
                        mdi-minus
                      </v-icon>
                    </template>

                    <template v-slot:append>
                      <v-icon color="success" @click="incrementRetExpRate">
                        mdi-plus
                      </v-icon>
                    </template>
                  </v-slider></v-col
                >
                <v-col
                  cols="4"
                  sm="12"
                  md="12"
                  lg="5"
                  class="text-center mt-2 mb-n1"
                >
                  <span
                    v-if="
                      selectedItem.currentData.retirementExpenseRate !==
                        parameters.retirementExpenseRate
                    "
                    class="subheading font-weight-light mr-1"
                    v-text="
                      '(' + selectedItem.currentData.retirementExpenseRate + ')'
                    "
                  ></span>
                  <span
                    class="headline primary--text mr-1"
                    v-text="parameters.retirementExpenseRate"
                  ></span>
                  <span class="subheading font-weight-light mr-1">%</span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
      </v-col>

      <v-col cols="6">
        <template>
          <v-card flat class="mx-auto mt-n2">
            <v-card-text>
              <v-row class="mt-n6" justify="space-between"
                ><v-col class="text-left">
                  <span class="overline primary--text font-weight-normal"
                    >POST RETIREMENT RISK TYPE / YEAR
                  </span>
                </v-col>
              </v-row>
              <v-row
                v-if="parameters.postRetirementRiskType != 'current'"
                class="mt-n8"
                justify="space-between"
              >
                <v-col cols="8" sm="12" md="12" lg="8" class="text-left mb-n6"
                  ><v-select
                    v-model="parameters.postRetirementRiskType"
                    :items="postRetirementRiskTypeList"
                    class="primary--text"
                  ></v-select
                ></v-col>
                <v-col cols="4" sm="12" md="12" lg="4" class="text-center mb-n6"
                  ><v-select
                    v-if="parameters.postRetirementRiskType != 'current'"
                    v-model="parameters.postRetirementRiskTypeFromYear"
                    :items="postRetirementRiskTypeFromYearItems"
                    prefix="/"
                    class="primary--text"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row v-else class="mt-n8" justify="space-between">
                <v-col cols="8" sm="12" md="12" lg="9" class="text-left mb-n6"
                  ><v-select
                    v-model="parameters.postRetirementRiskType"
                    :items="postRetirementRiskTypeList"
                    class="primary--text"
                  ></v-select
                ></v-col>
                <v-col cols="4" sm="12" md="12" lg="3" class="text-center mb-n6"
                  ><v-select
                    v-if="parameters.postRetirementRiskType != 'current'"
                    v-model="parameters.postRetirementRiskTypeFromYear"
                    :items="postRetirementRiskTypeFromYearItems"
                    prefix="/"
                    class="primary--text"
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
      </v-col>
    </v-row>

    <v-divider color="clientLayout" class="mt-n3"></v-divider>

    <v-row>
      <v-col cols="6">
        <template>
          <v-card flat class="mx-auto">
            <v-card-text>
              <v-row class="mt-n6" justify="space-between"
                ><v-col cols="12" class="text-left">
                  <span class="overline primary--text font-weight-normal"
                    >{{ clientPerson1.firstName }}'s RETIREMENT AGE
                  </span>
                </v-col></v-row
              >

              <v-row class="mt-n6" justify="space-between">
                <v-col cols="8" sm="12" md="12" lg="7" class="text-left"
                  ><v-slider
                    v-model="parameters.retirementAgePerson1"
                    color="success"
                    track-color="grey"
                    :min="getMinRetirementAgePerson1"
                    :max="getMaxRetirementAgePerson1"
                    class="mb-n8"
                  >
                    <template v-slot:prepend>
                      <v-icon color="success" @click="decrementRetAgePerson1">
                        mdi-minus
                      </v-icon>
                    </template>

                    <template v-slot:append>
                      <v-icon color="success" @click="incrementRetAgePerson1">
                        mdi-plus
                      </v-icon>
                    </template>
                  </v-slider></v-col
                >

                <v-col cols="4" sm="12" md="12" lg="5" class="text-right">
                  <span
                    v-if="
                      selectedItem.currentData.retirementAgePerson1 !==
                        parameters.retirementAgePerson1
                    "
                    class="subheading font-weight-light mr-1"
                    v-text="
                      '(' + selectedItem.currentData.retirementAgePerson1 + ')'
                    "
                  ></span>
                  <span
                    class="headline primary--text mr-1"
                    v-text="parameters.retirementAgePerson1"
                  ></span>
                  <span class="subheading font-weight-light mr-1">YEARS</span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
      </v-col>

      <v-col cols="6">
        <template>
          <v-card flat class="mx-auto">
            <v-card-text>
              <v-row class="mt-n6" justify="space-between"
                ><v-col cols="8" class="text-left">
                  <span class="overline primary--text font-weight-normal"
                    >{{ clientPerson1.firstName }}'s LIFE EXPECTANCY</span
                  >
                </v-col>

                <v-col cols="4" class="text-right">
                  <dialog-info-text :text="infoTextLifeExpectancy" />
                </v-col>
              </v-row>

              <v-row class="mt-n7" justify="space-between">
                <v-col cols="8" sm="12" md="12" lg="7" class="text-left"
                  ><v-slider
                    v-model="parameters.lifeExpectancyPerson1"
                    color="success"
                    track-color="grey"
                    :min="getMinLifeExpectancyPerson1"
                    class="mb-n8"
                  >
                    <template v-slot:prepend>
                      <v-icon color="success" @click="decrementLifeExpPerson1">
                        mdi-minus
                      </v-icon>
                    </template>

                    <template v-slot:append>
                      <v-icon color="success" @click="incrementLifeExpPerson1">
                        mdi-plus
                      </v-icon>
                    </template>
                  </v-slider></v-col
                >

                <v-col cols="4" sm="12" md="12" lg="5" class="text-right">
                  <span
                    v-if="
                      getCurrentGovtLifeExpectancyPerson1 !==
                        parameters.lifeExpectancyPerson1
                    "
                    class="subheading font-weight-light mr-1"
                    v-text="'(' + getCurrentGovtLifeExpectancyPerson1 + ')'"
                  ></span>
                  <span
                    class="headline primary--text mr-1"
                    v-text="parameters.lifeExpectancyPerson1"
                  ></span>
                  <span class="subheading font-weight-light">YEARS</span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
      </v-col>
    </v-row>

    <v-divider color="clientLayout" class="mt-n3"></v-divider>

    <v-row v-if="clientProfileType == 'Joint'"
      ><v-col cols="6">
        <template>
          <v-card flat class="mx-auto">
            <v-card-text>
              <v-row class="mt-n6" justify="space-between"
                ><v-col class="text-left">
                  <span class="overline primary--text font-weight-normal"
                    >{{ clientPerson2.firstName }}'s RETIREMENT AGE</span
                  >
                </v-col></v-row
              >
              <v-row class="mt-n6" justify="space-between">
                <v-col cols="8" sm="12" md="12" lg="7" class="text-left"
                  ><v-slider
                    v-model="parameters.retirementAgePerson2"
                    color="success"
                    track-color="grey"
                    :min="getMinRetirementAgePerson2"
                    :max="getMaxRetirementAgePerson2"
                    class="mb-n8"
                  >
                    <template v-slot:prepend>
                      <v-icon color="success" @click="decrementRetAgePerson2">
                        mdi-minus
                      </v-icon>
                    </template>

                    <template v-slot:append>
                      <v-icon color="success" @click="incrementRetAgePerson2">
                        mdi-plus
                      </v-icon>
                    </template>
                  </v-slider></v-col
                >
                <v-col cols="4" sm="12" md="12" lg="5" class="text-right">
                  <span
                    v-if="
                      selectedItem.currentData.retirementAgePerson2 !==
                        parameters.retirementAgePerson2
                    "
                    class="subheading font-weight-light mr-1"
                    v-text="
                      '(' + selectedItem.currentData.retirementAgePerson2 + ')'
                    "
                  ></span>
                  <span
                    class="headline primary--text mr-1"
                    v-text="parameters.retirementAgePerson2"
                  ></span>
                  <span class="subheading font-weight-light">YEARS</span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
      </v-col>

      <v-col cols="6">
        <template>
          <v-card flat class="mx-auto">
            <v-card-text>
              <v-row class="mt-n6" justify="space-between"
                ><v-col cols="10" class="text-left">
                  <span class="overline primary--text font-weight-normal"
                    >{{ clientPerson2.firstName }}'s LIFE EXPECTANCY</span
                  >
                </v-col>
                <v-col cols="2" class="text-right">
                  <dialog-info-text :text="infoTextLifeExpectancy" />
                </v-col>
              </v-row>
              <v-row class="mt-n7" justify="space-between">
                <v-col cols="8" sm="12" md="12" lg="7" class="text-left"
                  ><v-slider
                    v-model="parameters.lifeExpectancyPerson2"
                    color="success"
                    track-color="grey"
                    :min="getMinLifeExpectancyPerson2"
                    class="mb-n8"
                  >
                    <template v-slot:prepend>
                      <v-icon color="success" @click="decrementLifeExpPerson2">
                        mdi-minus
                      </v-icon>
                    </template>

                    <template v-slot:append>
                      <v-icon color="success" @click="incrementLifeExpPerson2">
                        mdi-plus
                      </v-icon>
                    </template>
                  </v-slider></v-col
                >
                <v-col cols="4" sm="12" md="12" lg="5" class="text-right">
                  <span
                    v-if="
                      getCurrentGovtLifeExpectancyPerson2 !==
                        parameters.lifeExpectancyPerson2
                    "
                    class="subheading font-weight-light mr-1"
                    v-text="'(' + getCurrentGovtLifeExpectancyPerson2 + ')'"
                  ></span>
                  <span
                    class="headline primary--text mr-1"
                    v-text="parameters.lifeExpectancyPerson2"
                  ></span>
                  <span class="subheading font-weight-light">YEARS</span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { authComputed } from '@/vuex/helpers.js'
import InfoTextService from '@/services/InfoTextService.js'
import DataServiceLifeExpectancy from '@/services/DataServiceLifeExpectancy.js'
import DialogInfoText from '@/components/dialogs/DialogInfoText.vue'

export default {
  name: 'PlanRetirementGeneral',

  props: { selectedItem: { type: Object } },

  components: {
    DialogInfoText
  },

  data() {
    return {}
  },

  watch: {
    // watch change of data and if it happens, emit the change to the parent (financialPlan) component
    proposedDataChanged: 'dataChange'
  },

  methods: {
    dataChange() {
      this.$emit('showHideSaveButton', this.proposedDataChanged)
    },
    decrementRetAgePerson1() {
      this.parameters.retirementAgePerson1--
    },
    incrementRetAgePerson1() {
      this.parameters.retirementAgePerson1++
    },
    decrementLifeExpPerson1() {
      this.parameters.lifeExpectancyPerson1--
    },
    incrementLifeExpPerson1() {
      this.parameters.lifeExpectancyPerson1++
    },
    decrementRetAgePerson2() {
      this.parameters.retirementAgePerson2--
    },
    incrementRetAgePerson2() {
      this.parameters.retirementAgePerson2++
    },
    decrementLifeExpPerson2() {
      this.parameters.lifeExpectancyPerson2--
    },
    incrementLifeExpPerson2() {
      this.parameters.lifeExpectancyPerson2++
    },
    decrementRetExp() {
      this.parameters.retirementExpenseValue -= 5000
    },
    incrementRetExp() {
      this.parameters.retirementExpenseValue += 5000
    },
    decrementRetExpRate() {
      this.parameters.retirementExpenseRate -= 5
    },
    incrementRetExpRate() {
      this.parameters.retirementExpenseRate += 5
    },
    dataChanged(object1, object2) {
      const keys1 = Object.keys(object1)
      const keys2 = Object.keys(object2)
      if (keys1.length !== keys2.length) {
        return true
      }
      for (let key of keys1) {
        if (object1[key] !== object2[key]) {
          return true
        }
      }
      return false
    }
  },

  computed: {
    infoTextRetirementExpenseFixed() {
      return InfoTextService.getRetirementExpenseFixedInfo()
    },

    infoTextLifeExpectancy() {
      return InfoTextService.getRetirementLifeExpectancyInfo()
    },

    parameters: {
      get() {
        return this.clientProjection.parameters
      },
      set(parameters) {
        console.log('Parameters = ', parameters)
        this.$store.commit('SET_FINANCIAL_PLAN_PARAMETERS', parameters)
      }
    },

    getCurrentGovtLifeExpectancyPerson1() {
      return this.clientPerson1.gender == 'male' ||
        this.clientPerson1.gender == 'female'
        ? DataServiceLifeExpectancy.getLifeExpectancy(
            this.clientPerson1.gender,
            this.clientPerson1.currentAge,
            Number(this.clientPerson1.birthDate.substring(0, 4))
          )
        : 100 // default value for intersex and non-binary genders (due to the lack of govt data)
    },

    getCurrentGovtLifeExpectancyPerson2() {
      return this.clientPerson2.gender == 'male' ||
        this.clientPerson2.gender == 'female'
        ? DataServiceLifeExpectancy.getLifeExpectancy(
            this.clientPerson2.gender,
            this.clientPerson2.currentAge,
            Number(this.clientPerson2.birthDate.substring(0, 4))
          )
        : 100 // default value for intersex and non-binary genders (due to the lack of govt data)
    },

    getMinLifeExpectancyPerson1() {
      return this.clientProjection.parameters.retirementAgePerson1 + 1
    },

    getMinLifeExpectancyPerson2() {
      return this.clientProjection.parameters.retirementAgePerson2 + 1
    },

    getMinRetirementAgePerson1() {
      return this.clientPerson1.currentAge + 1 >=
        this.selectedItem.currentData.retirementAgePerson1
        ? this.selectedItem.currentData.retirementAgePerson1
        : this.clientPerson1.currentAge + 1
    },

    getMinRetirementAgePerson2() {
      return this.clientPerson2.currentAge + 1 >=
        this.selectedItem.currentData.retirementAgePerson2
        ? this.selectedItem.currentData.retirementAgePerson2
        : this.clientPerson2.currentAge + 1
    },

    getMaxRetirementAgePerson1() {
      return this.clientProjection.parameters.lifeExpectancyPerson1 >
        this.clientPerson1.currentAge
        ? this.clientProjection.parameters.lifeExpectancyPerson1
        : this.clientPerson1.currentAge
    },

    getMaxRetirementAgePerson2() {
      return this.clientProjection.parameters.lifeExpectancyPerson2 >
        this.clientPerson2.currentAge
        ? this.clientProjection.parameters.lifeExpectancyPerson2
        : this.clientPerson2.currentAge
    },

    postRetirementRiskTypeList() {
      let list = []
      Object.entries(this.adminDefinitionsRiskTypes).forEach(([, riskType]) => {
        if (this.clientLedger.ledger.conditions.riskType == riskType.name) {
          list.push({
            text:
              this.clientLedger.ledger.conditions.riskType +
              ' (' +
              riskType.returnRate +
              ' %) current',
            value: 'current'
          })
        } else {
          list.push({
            text: riskType.name + ' (' + riskType.returnRate + ' %)',
            value: riskType.name
          })
        }
      })

      return list
    },

    // check, if data has been changed to enable showing of save changes button and save changes dialog
    proposedDataChanged() {
      return this.dataChanged(this.selectedItem.proposedData, this.parameters)
    },

    postRetirementRiskTypeFromYearItems() {
      let items = []
      let min = 0
      let yearPerson1 =
        Number(this.clientPerson1.birthDate.substring(0, 4)) +
        this.clientProjection.parameters.retirementAgePerson1
      // get earliest retirement year individual account
      if (this.clientProfile.type == 'Individual') {
        min =
          yearPerson1 >=
          this.selectedItem.proposedData.postRetirementRiskTypeFromYear
            ? this.selectedItem.proposedData.postRetirementRiskTypeFromYear
            : yearPerson1
      }
      // get earliest retirement year joint account
      else {
        let retYears = [
          { year: yearPerson1 },
          {
            year:
              Number(this.clientPerson2.birthDate.substring(0, 4)) +
              this.clientProjection.parameters.retirementAgePerson2
          }
        ]
        retYears.sort(function(a, b) {
          return a.year - b.year
        })
        min =
          parseInt(retYears[0].year) >=
          this.selectedItem.proposedData.postRetirementRiskTypeFromYear
            ? this.selectedItem.proposedData.postRetirementRiskTypeFromYear
            : parseInt(retYears[0].year)
      }
      // start with the earliest retirement year and add the following 30 years (range)

      let max = min + 30
      let step = 1
      for (let year = min; year <= max; year += step) {
        items.push(parseInt(year))
      }
      return items
    },

    ...mapState(['clientLedger', 'clientProjection', 'client']),

    ...authComputed
  }
}
</script>

<style lang="scss" scoped></style>
