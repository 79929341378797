<template>
  <div>
    <v-card flat class="mx-auto text-left mt-9">
      <v-textarea
        v-model="parameters.comment"
        auto-grow
        filled
        color="clientLayout"
        :label="
          parameters.comment == ''
            ? 'Type your comment here ...'
            : 'Comment Board'
        "
        shaped
      ></v-textarea>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { authComputed } from '@/vuex/helpers.js'

export default {
  name: 'PlanComment',

  props: { selectedItem: { type: Object } },

  data() {
    return {
      comment: ''
    }
  },

  watch: {
    // watch change of data and if it happens, emit the change to the parent (financialPlan) component
    proposedDataChanged: 'dataChange'
  },

  methods: {
    dataChange() {
      this.$emit('showHideSaveButton', this.proposedDataChanged)
    },
    dataChanged(object1, object2) {
      const keys1 = Object.keys(object1)
      const keys2 = Object.keys(object2)
      if (keys1.length !== keys2.length) {
        return true
      }
      for (let key of keys1) {
        if (object1[key] !== object2[key]) {
          return true
        }
      }
      return false
    }
  },

  computed: {
    parameters: {
      get() {
        return this.clientProjection.parameters
      },
      set(parameters) {
        console.log('Parameters = ', parameters)
        this.$store.commit('SET_FINANCIAL_PLAN_PARAMETERS', parameters)
      }
    },

    ...mapState(['clientLedger', 'clientProjection', 'client']),

    ...authComputed
  }
}
</script>

<style lang="scss" scoped></style>
