<template>
  <div>
    <v-card flat
      ><div id="chart">
        <apexchart
          type="area"
          height="375"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { authComputed } from '@/vuex/helpers.js'
import SystemService from '@/services/SystemService.js'

export default {
  name: 'PlanRetirementGeneralChart',

  data() {
    return {
      // has to be made dynamic in the future
      netInvestmentReturn: 6,
      extraSavings: 0
    }
  },

  methods: {
    presentValue(value, years, inflation, growthRate) {
      var pve = 0
      var i
      for (i = 0; i <= years; i++) {
        pve =
          pve +
          (value * (1 + inflation / 100) ** i) / (1 + growthRate / 100) ** i
      }
      return pve
    },

    maxYAxis() {
      let maxYAxisNumber = this.roundUpNumber(
        this.assetsNeededAtRetirement * 1.1,
        1000000
      )
      // remove commas from string and convert into float
      return parseFloat(maxYAxisNumber)
    },

    roundUpNumber(num, precision) {
      num = parseFloat(num)
      if (!precision) return num
      return Math.ceil(num / precision) * precision
    }
  },

  computed: {
    parameters: {
      get() {
        return this.clientProjection.parameters
      },
      set(parameters) {
        console.log('Parameters = ', parameters)
        this.$store.commit('SET_FINANCIAL_PLAN_PARAMETERS', parameters)
      }
    },

    conditions: {
      get() {
        return this.clientLedger.ledger.conditions
      },
      set(attributes) {
        this.$store.commit('SET_CLIENT_LEDGER_CONDITIONS', attributes)
      }
    },

    getCurrentAge() {
      let age = this.clientPerson1.currentAge
      return age
    },

    incomeRetirementNeed() {
      return (
        this.parameters.retirementExpenseValue *
        (1 + this.conditions.inflationRate / 100) **
          (this.parameters.retirementAgePerson1 - this.clientPerson1.currentAge)
      )
    },

    pvExpenditure() {
      var years =
        this.parameters.lifeExpectancyPerson1 -
        this.parameters.retirementAgePerson1
      return this.presentValue(
        this.incomeRetirementNeed,
        years,
        this.conditions.inflationRate,
        this.netInvestmentReturn
      )
    },

    pvOtherIncome() {
      var years =
        this.parameters.lifeExpectancyPerson1 -
        this.parameters.retirementAgePerson1

      return this.presentValue(
        this.incomeAtRetirement,
        years,
        this.conditions.inflationRate,
        this.netInvestmentReturn
      )
    },

    assetsAverageGrowth() {
      var combinedValue = 0
      var growthPA = 0
      Object.entries(this.clientAssets).forEach(([, asset]) => {
        combinedValue += asset.priceTodayApp
        growthPA += (asset.priceTodayApp * asset.growthRate) / 100
      })
      return (growthPA / combinedValue) * 100
    },

    assetValueAtRetirement() {
      var avr = 0
      Object.entries(this.clientAssets).forEach(([, asset]) => {
        avr =
          avr +
          asset.priceTodayApp *
            (1 + this.netInvestmentReturn / 100) **
              (this.parameters.retirementAgePerson1 -
                this.clientPerson1.currentAge)
      })
      return avr
    },

    assetValueEachYear() {
      var avey = []
      var i = this.getCurrentAge
      for (i; i <= this.parameters.retirementAgePerson1; i++) {
        var avr = 0
        Object.entries(this.clientAssets).forEach(([, asset]) => {
          avr =
            avr +
            asset.priceTodayApp *
              (1 + this.netInvestmentReturn / 100) **
                (i - this.clientPerson1.currentAge)
        })
        avey.push(avr)
      }
      return avey
    },

    incomeAtRetirement() {
      var iar = 0
      Object.entries(this.clientIncomeSources).forEach(([, income]) => {
        iar = iar + income.incomePaLocal
        // no growth yet
      })
      return iar
    },

    // Income part deactivated
    assetsNeededAtRetirement() {
      return this.pvExpenditure // - this.pvOtherIncome
    },

    assetsAvailableAtRetirement() {
      return this.assetValueAtRetirement
    },

    assetDeltaAtRetirement() {
      return this.assetsAvailableAtRetirement - this.assetsNeededAtRetirement
    },

    assetDeltaAtRetirementPercentage() {
      return Math.round(
        (this.assetsAvailableAtRetirement / this.assetsNeededAtRetirement) * 100
      )
    },

    assetsNeededEachYearTillRetirement() {
      var aneytr = []
      var i = this.getCurrentAge
      for (i; i <= this.parameters.retirementAgePerson1; i++) {
        aneytr.push(
          (
            this.assetsNeededAtRetirement /
            (1 + this.netInvestmentReturn / 100) **
              (this.parameters.retirementAgePerson1 - i)
          ).toFixed(0)
        )
      }
      return aneytr
    },

    assetsNeededEachYearAfterRetirement() {
      var aneyar = []
      var i = this.parameters.retirementAgePerson1 + 1
      for (i; i <= this.parameters.lifeExpectancyPerson1; i++) {
        aneyar.push(
          this.presentValue(
            this.incomeRetirementNeed *
              (1 + this.conditions.inflationRate / 100) **
                (i - this.parameters.retirementAgePerson1),
            this.parameters.lifeExpectancyPerson1 - i,
            this.conditions.inflationRate,
            this.netInvestmentReturn
          ).toFixed(0)
        )
      }
      console.log('assets needed after retirement', aneyar)
      return aneyar
    },

    assetsNeededEachYear() {
      let value = this.assetsNeededEachYearTillRetirement.concat(
        this.assetsNeededEachYearAfterRetirement
      )
      return value
    },

    chartXAxis() {
      var xAxis = []
      var i = this.getCurrentAge
      for (i; i <= this.parameters.lifeExpectancyPerson1; i++) {
        xAxis.push(i)
      }
      return xAxis
    },

    // Processing of "What you have" Projection, based on current assets, liabilities and extra savings
    projection() {
      let data = { total: [], investments: [], extraSavings: [] }

      // console.log('length = ', this.chartXAxis.length)
      for (let year = 0; year < this.chartXAxis.length; year++) {
        let balanceYearlyAssets = 0
        let balanceYearlyInvestments = 0
        // let balanceYearlyInvestmentsNegative = 0 // negative investment balance after running out of money
        let balanceYearlyLiabilities = 0
        let extra = 0 // extra savings balance (31.12.xx) for each year pre retirement
        let expensesYearly = 0 // future value of expenses for each year post retirement

        // calculate the future value of expenses for each year after retirement
        if (
          // pre retirement
          year <=
          this.parameters.retirementAgePerson1 - this.clientPerson1.currentAge
        ) {
          expensesYearly = 0
        } else {
          // post retirement
          expensesYearly =
            this.parameters.retirementExpenseValue *
            (1 + this.conditions.inflationRate / 100) ** year
        }

        console.log('expensesYearly = ', expensesYearly)

        // process assets pre retirement (1. total assets and 2. investments)
        if (
          this.parameters.retirementAgePerson1 -
            this.clientPerson1.currentAge >=
          year
        ) {
          Object.entries(this.clientAssets).forEach(([, asset]) => {
            let pointer =
              Number(SystemService.yearToday()) - Number(asset.projection.year)

            // calculate total asset value for each year (1. all asset classes)
            // check if individual asset has different local currency than AppCurrency
            if (asset.currencyLocal != this.currencyApp) {
              let assetValue =
                (asset.projection.data[pointer + year] /
                  this.fxRateToday[asset.currencyLocal]) *
                this.fxRateToday[this.currencyApp]
              balanceYearlyAssets += Number(assetValue.toFixed(0))
            } else {
              // console.log('assetValue = ', asset.projection.data[year])
              balanceYearlyAssets += asset.projection.data[pointer + year]
            }

            // calculate total investment value for each year (2. investment class only)
            if (asset.class == 'Investment') {
              // check if individual asset has different local currency than AppCurrency
              if (asset.currencyLocal != this.currencyApp) {
                let investmentValue =
                  (asset.projection.data[pointer + year] /
                    this.fxRateToday[asset.currencyLocal]) *
                  this.fxRateToday[this.currencyApp]
                balanceYearlyInvestments += Number(investmentValue.toFixed(0))
              } else {
                // console.log('assetValue = ', asset.projection.data[year])
                balanceYearlyInvestments +=
                  asset.projection.data[pointer + year]
              }
            }
          })
        }

        // process assets post retirement
        // asset data series are irrelevant now, calculate balances based on total value at retirement
        else {
          // calculate investment balance for each year post retirement
          balanceYearlyInvestments = data.investments[year - 1]
          if (balanceYearlyInvestments >= expensesYearly) {
            // calculate balance at the end of the year (deduct expenses monthly and take investment return into account)
            for (let month = 1; month <= 12; month++) {
              balanceYearlyInvestments -= expensesYearly / 12
              balanceYearlyInvestments =
                balanceYearlyInvestments *
                (1 + this.netInvestmentReturn / 12 / 100)
            }
          }
          //  else {
          //   // balance is turning negative, investments are gone
          //   if (balanceYearlyInvestmentsNegative == 0) {
          //     // first year, when investments are running negative
          //     balanceYearlyInvestmentsNegative =
          //       balanceYearlyInvestments - expensesYearly
          //     balanceYearlyInvestments = balanceYearlyInvestmentsNegative
          //   } else {
          //     // any following years, investments are negative
          //     balanceYearlyInvestments =
          //       balanceYearlyInvestmentsNegative * -1 - expensesYearly
          //     balanceYearlyInvestments = balanceYearlyInvestmentsNegative
          //   }
          // }
          // console.log(
          //   'balanceYearlyInvestmNegative = ',
          //   balanceYearlyInvestmentsNegative
          // )
          // calculate asset balance for each year post retirement
          // balanceYearlyAssets = data.total[year - 1] - expensesYearly
          Object.entries(this.clientAssets).forEach(([, asset]) => {
            let pointer =
              Number(SystemService.yearToday()) - Number(asset.projection.year)

            // calculate total asset value (excluding Investments) for each year
            // check if individual asset has different local currency than AppCurrency
            if (asset.class != 'Investment') {
              if (asset.currencyLocal != this.currencyApp) {
                let assetValue =
                  (asset.projection.data[pointer + year] /
                    this.fxRateToday[asset.currencyLocal]) *
                  this.fxRateToday[this.currencyApp]
                balanceYearlyAssets += Number(assetValue.toFixed(0))
              } else {
                // console.log('assetValue = ', asset.projection.data[year])
                balanceYearlyAssets += asset.projection.data[pointer + year]
              }
            }
          })
          // console.log('balanceYearlyInvestments =', balanceYearlyInvestments)
          // console.log('balanceYearlyAssets before =', balanceYearlyAssets)
          balanceYearlyAssets += balanceYearlyInvestments
          // console.log('balanceYearlyAssets after =', balanceYearlyAssets)
        }

        // process liabilities
        Object.entries(this.clientLiabilities).forEach(([, liability]) => {
          let pointer =
            Number(SystemService.yearToday()) -
            Number(liability.projection.year)

          if (
            // balance for currently processing "year" is available in data series
            pointer + year <=
            Number(liability.maturityDate.substring(0, 4)) -
              Number(liability.closingDate.substring(0, 4))
          ) {
            // check if individual liability has different local currency than AppCurrency
            if (liability.currencyLocal != this.currencyApp) {
              let LiabilityBalance =
                (liability.projection.data[pointer + year] /
                  this.fxRateToday[liability.currencyLocal]) *
                this.fxRateToday[this.currencyApp]
              balanceYearlyLiabilities += Number(LiabilityBalance.toFixed(0))
            } else {
              balanceYearlyLiabilities +=
                liability.projection.data[pointer + year]
            }
          } else {
            // balance for currently processing "year" is not available in data series, use final year balance instead
            let maxIndex = liability.projection.data.length - 1
            // console.log('length =', maxIndex)
            // check if individual liability has different local currency than AppCurrency
            if (liability.currencyLocal != this.currencyApp) {
              let num =
                (liability.projection.data[maxIndex] /
                  this.fxRateToday[liability.currencyLocal]) *
                this.fxRateToday[this.currencyApp]
              balanceYearlyLiabilities += Number(num.toFixed(0))
            } else {
              balanceYearlyLiabilities += liability.projection.data[maxIndex]
            }
          }
        })

        // process extra savings
        if (year == 0) {
          // calculate pro rata extra savings at the end of the current year (first year)
          extra =
            (this.extraSavings *
              12 *
              SystemService.getNumberOfDays(
                SystemService.yearToday() + '-12-31',
                SystemService.dateToday()
              )) /
            365
          data.extraSavings.push(Number(extra.toFixed(0)))
        } else if (
          // full years pre retirement
          this.parameters.retirementAgePerson1 -
            this.clientPerson1.currentAge >=
          year
        ) {
          // calculate the years until retirement (with extra savings contribution and investment return every year)
          extra =
            (this.extraSavings * 12 + data.extraSavings[year - 1]) *
            (1 + this.netInvestmentReturn / 100)
          data.extraSavings.push(Number(extra.toFixed(0)))
        }

        // else {
        // calculate the years after retirement (without extra savings contribution, just investment return)
        // num =
        // data.extraSavings[year - 1] *
        // (1 + this.netInvestmentReturn / 100)
        // }
        // data.extraSavings.push(Number(num.toFixed(0)))
        // console.log('expensesYearly = ', expensesYearly)
        // console.log('balanceYearlyInvestments = ', balanceYearlyInvestments)

        // process current year up to the final, good retirement year (timeframe, where investments are available)
        if (balanceYearlyInvestments != undefined) {
          data.total.push(
            Number(balanceYearlyAssets.toFixed(0)) +
              Number(extra.toFixed(0)) -
              Number(balanceYearlyLiabilities.toFixed(0))
          )
          if (
            // push balanceYearlyInvestments if balance is positive
            Number(balanceYearlyInvestments.toFixed(0)) +
              Number(extra.toFixed(0)) >
            0
          ) {
            data.investments.push(
              Number(balanceYearlyInvestments.toFixed(0)) +
                Number(extra.toFixed(0))
            )
          } else {
            // push 0 if balance is negative to align graph with 0
            data.investments.push(0)
          }
        }
        // console.log('sum yearly Assets = ', sumYearlyAssets)
        // console.log('sum yearly Liab = ', sumYearlyLiabilities)
      }
      console.log('data =', data)
      return data
    },

    series() {
      return [
        {
          name: 'Retirement Goal',
          type: 'line',
          data: this.assetsNeededEachYearTillRetirement.concat(
            this.assetsNeededEachYearAfterRetirement
          )
        },
        {
          name: 'NetWorth',
          type: 'area',
          data: this.projection.total
        },
        {
          name: 'Investments (Class)',
          type: 'area',
          data: this.projection.investments
        },
        {
          name: 'Extra Savings',
          type: 'area',
          data: this.projection.extraSavings
        }
      ]
    },

    chartOptions() {
      let currency = this.currencyApp
      let retirementAge = this.parameters.retirementAgePerson1
      let currentAge = this.getCurrentAge
      let goal = this.assetsNeededAtRetirement
      // let assetsNeededAtRetirement = this.assetsNeededAtRetirement.toLocaleString(
      //   undefined,
      //   {
      //     minimumFractionDigits: 0,
      //     maximumFractionDigits: 0
      //   }
      // )

      var xAxis = []
      var i = this.getCurrentAge
      for (i; i <= this.parameters.lifeExpectancyPerson1; i++) {
        xAxis.push(i)
      }
      return {
        chart: {
          stacked: false,
          toolbar: {
            offsetY: 0,
            tools: {
              download: false,
              pan: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              reset: false
            }
          }
        },
        colors: ['#f47d20', '#bdbdbd', '#778f9b', '#b00020'],
        markers: {
          colors: ['#bdbdbd', '#778f9b', '#b00020', '#f47d20']
        },
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'center',
          offsetX: -30,
          offsetY: 12,
          fontSize: '12px',
          fontWeight: 500,
          fontFamily: 'Montserrat'
        },
        grid: {
          show: true,
          borderColor: '#eeeeee',
          yaxis: {
            lines: {
              show: true
            }
          },
          xaxis: {
            lines: {
              show: false
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight',
          width: 2
        },
        annotations: {
          xaxis: [
            {
              x: retirementAge,
              borderColor: '#FEB019',
              label: {
                borderColor: '#FEB019',
                style: {
                  color: 'white',
                  background: '#f47d20',
                  fontSize: '1rem',
                  fontWeight: 500,
                  fontFamily: 'Roboto'
                },
                textAnchor: 'start',
                orientation: 'horizontal'
                // text: 'Goal:' + ' ' + assetsNeededAtRetirement + ' ' + currency
              }
            }
          ],
          points: [
            {
              x: retirementAge - currentAge + 1,
              y: goal,
              marker: {
                size: 8,
                strokeColor: '#f47d20',
                strokeWidth: 3
              },
              label: {
                borderColor: '#f47d20',
                borderWidth: 2,
                offsetX: 20,
                offsetY: 30,
                style: {
                  color: '#31465F',
                  background: 'white',
                  fontSize: '0.875rem',
                  fontWeight: 500,
                  fontFamily: 'Montserrat'
                },
                textAnchor: 'start',
                // text: 'Goal:' + ' ' + assetsNeededAtRetirement + ' ' + currency
                text: 'Retirement Goal'
              }
            }
          ]
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.7,
            opacityTo: 0.7,
            stops: [20, 100, 100, 100]
          }
        },
        xaxis: {
          type: 'category',
          categories: xAxis,
          title: {
            text: 'Age',
            offsetY: 0,
            style: {
              color: '#31465F',
              fontSize: '0.875rem',
              fontWeight: 500,
              fontFamily: 'Montserrat'
            }
          },
          labels: {
            show: true,
            rotate: 0
          },
          axisTicks: {
            show: false
          }
          // tickAmount: 10
        },
        yaxis: {
          type: 'numeric',
          title: {
            text: 'NetWorth',
            offsetX: 0,
            style: {
              color: '#31465F',
              fontSize: '0.875rem',
              fontWeight: 500,
              fontFamily: 'Montserrat'
            }
          },
          axisBorder: {
            show: false
          },
          labels: {
            formatter: function(value) {
              return value / 1000 + 'k ' + currency
            }
          },
          forceNiceScale: true,
          max: this.maxYAxis()
        },
        tooltip: {
          followCursor: true,
          // marker: {
          //  show: false
          // }
          // enabled: true,
          // enabledOnSeries: ['What you need', 'What you have'],
          // shared: true,
          // This custom function needs a "description" attribute in each ersies data object
          // custom: function(opts) {
          //   const desc =
          //     opts.ctx.w.config.series[opts.seriesIndex].data[
          //       opts.dataPointIndex
          //     ].description
          //   const value = opts.series[opts.seriesIndex][opts.dataPointIndex]
          //   return desc + ': ' + value
          // }
          custom: function({ series, seriesIndex, dataPointIndex }) {
            return (
              '<div >' +
              '<span>' +
              series[seriesIndex][dataPointIndex].toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              }) +
              '</span>' +
              ' ' +
              currency +
              '</div>'
            )
          }
        }
      }
    },
    ...mapState(['client', 'clientLedger', 'clientProjection']),

    ...authComputed
  }
}
</script>

<style lang="scss" scoped></style>
