var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[[_c('v-card',{staticClass:"mx-auto mb-n4",attrs:{"flat":""}},[_c('v-divider',{staticClass:"mt-n5",attrs:{"color":"clientLayout"}}),_c('v-card-text',[_c('v-row',{staticClass:"mt-n6",attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"text-left"},[_c('span',{staticClass:"overline primary--text"},[_vm._v("INVESTMENTS (CLASS) ")])])],1),_c('v-row',{staticClass:"mt-n6",attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"4","sm":"12","md":"4","lg":"4","xl":"4"}},[_c('v-slider',{attrs:{"color":"#778f9b","track-color":"grey","min":"0","max":"1500","step":"50"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{attrs:{"color":"#778f9b"},on:{"click":_vm.decrementExtraSavings}},[_vm._v(" mdi-minus ")])]},proxy:true},{key:"append",fn:function(){return [_c('v-icon',{attrs:{"color":"#778f9b"},on:{"click":_vm.incrementExtraSavings}},[_vm._v(" mdi-plus ")])]},proxy:true}]),model:{value:(_vm.extraSavings),callback:function ($$v) {_vm.extraSavings=$$v},expression:"extraSavings"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"8","sm":"12","md":"8","lg":"8","xl":"8"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"headline primary--text mr-1",domProps:{"textContent":_vm._s('1,212,056')}}),(_vm.contrPercent < 10)?_c('span',{staticClass:"subheading pr-6"},[_vm._v(_vm._s(_vm.currencyApp)+" ")]):(_vm.contrPercent >= 10 && _vm.extraSavings < 100)?_c('span',{staticClass:"subheading pr-3"},[_vm._v(_vm._s(_vm.currencyApp)+" ")]):_c('span',{staticClass:"subheading"},[_vm._v(_vm._s(_vm.currencyApp)+" ")]),_c('span',{staticClass:"headline primary--text",domProps:{"textContent":_vm._s(
                        _vm.contrPercent.toLocaleString(undefined, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0
                        })
                      )}}),_c('span',{staticClass:"subheading",domProps:{"textContent":_vm._s(' % / ')}}),_c('span',{staticClass:"subheading pr-2",domProps:{"textContent":_vm._s('2024')}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"success"},on:{"click":function($event){return _vm.deleteConfirm(_vm.dataItem)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}])},[_c('span',[_vm._v("Delete")])])],1)],1)],1)],1)],1)],1)]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }