<template>
  <div>
    <template>
      <v-btn
        depressed
        plain
        @click.stop="visible = !visible"
        color="transparent"
      >
        <v-icon color="clientLayout" dark>
          mdi-information-outline
        </v-icon></v-btn
      >
    </template>
    <v-dialog v-model="visible" max-width="500px">
      <v-card class="subtitle-1">
        <v-card-text
          class="title font-weight-medium primary--text text-center pt-4"
        >
          <p>{{ text.headline }}</p>

          <div v-for="(paragraph, i) in text.paragraph" :key="i">
            <p class="body-2 text-left mt-n2">
              {{ paragraph }}
            </p>
          </div>

          <p v-if="text.source != ''" class="caption text-left mt-6">
            <span class="caption font-weight-bold text-left mt-n2"
              >Source:</span
            >
            {{ text.source }}
            <span class="caption text-left mt-n2">
              Please find further information
            </span>
            <a
              class="a-style text-decoration-underline"
              @click="linkOpen(text.url)"
              >here</a
            >
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="primary--text mr-2 mb-4"
            text
            small
            @click="visible = !visible"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'DialogInfoText',

  props: {
    text: { type: Object }
  },

  data() {
    return {
      visible: false
    }
  },

  methods: {
    linkOpen(url) {
      window.open(url, '_blank') // New window opens external link
    }
  },

  computed: {}
}
</script>

<style lang="scss" scoped></style>
