<template>
  <div>
    <input-advice :parameters="parameters" />

    <v-card flat class="mx-auto text-right">
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }"
          ><v-btn
            v-bind="attrs"
            v-on="on"
            color="success"
            fab
            right
            dark
            x-small
            depressed
            class="mr-3 mt-3"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn></template
        >
        <span>Add Advice</span>
      </v-tooltip>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { authComputed } from '@/vuex/helpers.js'
import InputAdvice from '@/components/inputs/InputAdvice.vue'

export default {
  name: 'PlanAdvice',

  props: { selectedItem: { type: Object } },

  components: {
    InputAdvice
  },

  data() {
    return {
      extraSavings: 0
    }
  },

  watch: {
    // watch change of data and if it happens, emit the change to the parent (financialPlan) component
    proposedDataChanged: 'dataChange'
  },

  methods: {
    dataChange() {
      this.$emit('showHideSaveButton', this.proposedDataChanged)
    },
    dataChanged(object1, object2) {
      const keys1 = Object.keys(object1)
      const keys2 = Object.keys(object2)
      if (keys1.length !== keys2.length) {
        return true
      }
      for (let key of keys1) {
        if (object1[key] !== object2[key]) {
          return true
        }
      }
      return false
    }
  },

  computed: {
    parameters: {
      get() {
        return this.clientProjection.parameters
      },
      set(parameters) {
        console.log('Parameters = ', parameters)
        this.$store.commit('SET_FINANCIAL_PLAN_PARAMETERS', parameters)
      }
    },

    ...mapState(['clientLedger', 'clientProjection', 'client']),

    ...authComputed
  }
}
</script>

<style lang="scss" scoped></style>
