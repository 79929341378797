<template>
  <div>
    <v-row>
      <v-col cols="12">
        <template>
          <v-card flat class="mx-auto mb-n4">
            <v-divider color="clientLayout" class="mt-n5"></v-divider>
            <v-card-text>
              <v-row class="mt-n6" justify="space-between"
                ><v-col class="text-left">
                  <span class="overline primary--text"
                    >INVESTMENTS (CLASS)
                  </span>
                </v-col>
              </v-row>

              <v-row class="mt-n6" justify="space-between">
                <v-col cols="4" sm="12" md="4" lg="4" xl="4" class="text-center"
                  ><v-slider
                    v-model="extraSavings"
                    color="#778f9b"
                    track-color="grey"
                    min="0"
                    max="1500"
                    step="50"
                  >
                    <template v-slot:prepend>
                      <v-icon color="#778f9b" @click="decrementExtraSavings">
                        mdi-minus
                      </v-icon>
                    </template>

                    <template v-slot:append>
                      <v-icon color="#778f9b" @click="incrementExtraSavings">
                        mdi-plus
                      </v-icon>
                    </template>
                  </v-slider></v-col
                >

                <v-col cols="8" sm="12" md="8" lg="8" xl="8" class="text-right">
                  <v-row
                    ><v-col cols="12">
                      <span
                        class="headline primary--text mr-1"
                        v-text="'1,212,056'"
                      ></span>

                      <span v-if="contrPercent < 10" class="subheading pr-6"
                        >{{ currencyApp }}
                      </span>
                      <span
                        v-else-if="contrPercent >= 10 && extraSavings < 100"
                        class="subheading pr-3"
                        >{{ currencyApp }}
                      </span>
                      <span v-else class="subheading">{{ currencyApp }} </span>

                      <span
                        class="headline primary--text"
                        v-text="
                          contrPercent.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                          })
                        "
                      ></span>
                      <span class="subheading" v-text="' % / '"></span>
                      <span class="subheading pr-2" v-text="'2024'"></span>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }"
                          ><v-icon
                            @click="deleteConfirm(dataItem)"
                            v-bind="attrs"
                            v-on="on"
                            color="success"
                            class="mb-2"
                          >
                            mdi-delete
                          </v-icon></template
                        >
                        <span>Delete</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { authComputed } from '@/vuex/helpers.js'

export default {
  name: 'InputFundingSource',

  props: { parameters: { type: Object } },

  data() {
    return {
      extraSavings: 0,
      contrPercent: 65
    }
  },

  methods: {
    decrementExtraSavings() {
      this.extraSavings -= 50
    },
    incrementExtraSavings() {
      this.extraSavings += 50
    },
    deleteConfirm() {}
  },

  computed: {
    extraSavingsMaxMonth() {
      return this.clientExtraSavingsMaxSum / 12 - this.extraSavings
    },

    assetNames() {
      let list = []
      Object.entries(this.clientAssetsName).forEach(([, asset]) => {
        if (
          asset.class == 'None' ||
          asset.class == 'Investment' ||
          asset.class == 'Precious Metal' ||
          asset.class == 'Crypto Currency' ||
          asset.class == 'Cash'
        ) {
          list.push(asset)
        }
      })
      return list
    },

    ...mapState(['clientLedger', 'clientProjection', 'client']),

    ...authComputed
  }
}
</script>

<style lang="scss" scoped></style>
