<template>
  <div>
    <apexchart
      type="radialBar"
      width="180"
      height="180"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
export default {
  name: 'ChartRadialBarRate',

  props: { series: { type: Array, default: () => null } },

  data() {
    return {
      extraSavings: 0,
      dialogInformation: false
    }
  },

  methods: {},

  computed: {
    chartOptions() {
      let data = {
        chart: {
          toolbar: {
            show: false
          }
        },
        colors: ['#009688'],
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: '65%',
              background: '#e0e0e0'
            },
            dataLabels: {
              name: {
                offsetY: 25,
                color: '#424242',
                fontSize: '12px',
                fontFamily: 'Montserrat'
              },
              value: {
                offsetY: -14,
                color: '#424242',
                fontSize: '34px',
                fontFamily: 'Montserrat',
                show: true
              }
            }
          }
        },
        fill: {
          colors: [
            function({ value }) {
              if (value < 33) {
                return '#B00020'
              } else if (value >= 33 && value < 66) {
                return '#f47d20'
              } else {
                return '#4CAF50'
              }
            }
          ],
          type: 'solid',
          gradient: {
            // shade: 'dark',
            type: 'horizontal',
            gradientToColors: ['#f47d20'],
            stops: [0, 100]
          }
        },
        // stroke: {
        //   lineCap: 'round'
        // },
        labels: ['funded']
      }

      return data
    }
  }
}
</script>

<style lang="scss" scoped></style>
