<template>
  <div>
    <v-card class="mx-auto" flat max-width="300" tile>
      <v-list nav dense flat class="mx-n8 mt-4">
        <v-list-item-group v-model="selected" color="success" multiple>
          <v-subheader class="primary--text text-uppercase subtitle-2"
            >Property<v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="grey" dark v-bind="attrs" v-on="on" class="ml-2">
                  mdi-information-outline
                </v-icon>
              </template>

              <span
                >Choose one asset (or a combination of assets) to get a
                projection of Networth / Income</span
              >
            </v-tooltip></v-subheader
          >
          <v-list-item v-for="item in itemsProperty" :key="item.id">
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.primaryText"></v-list-item-title>
              <v-list-item-subtitle
                v-text="item.secondaryText"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-subheader class="primary--text text-uppercase subtitle-2"
            >Investments</v-subheader
          >

          <v-list-item v-for="item in itemsInvestments" :key="item.id">
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.primaryText"></v-list-item-title>
              <v-list-item-subtitle
                v-text="item.secondaryText"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import { authComputed } from '@/vuex/helpers.js'
// import SystemService from '@/services/SystemService.js'
// import StaticDataService from '@/services/StaticDataService.js'

export default {
  name: 'clientProjectionAssetsNav',

  data() {
    return {
      selected: [1],
      itemsProperty: [
        {
          id: 1,
          primaryText: 'Own House',
          secondaryText: 'Purchased: 2011',
          icon: 'mdi-home'
        },
        {
          id: 2,
          primaryText: 'Flat Auckland',
          secondaryText: 'Purchased: 2015',
          icon: 'mdi-home'
        },
        {
          id: 3,
          primaryText: 'Warehouse Hamilton',
          secondaryText: 'Purchased 2019 / Proposed Sale 2029',
          icon: 'mdi-factory'
        }
      ],
      itemsInvestments: [
        {
          id: 4,
          primaryText: 'Portfolio',
          icon: 'mdi-note-text-outline'
        },
        {
          id: 5,
          primaryText: 'International Equity',
          icon: 'mdi-note-text-outline'
        },
        {
          id: 6,
          primaryText: 'Australasian Equity',
          icon: 'mdi-note-text-outline'
        },
        {
          id: 7,
          primaryText: 'Listed Property',
          icon: 'mdi-note-text-outline'
        }
      ]
    }
  },

  methods: {},

  computed: {
    ...authComputed
  }
}
</script>

<style lang="scss" scoped></style>
