var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{staticClass:"text-left",attrs:{"cols":"2","sm":"2","md":"2","lg":"1","xl":"1"}},[_c('v-checkbox',{staticClass:"subtitle-1 primary--text font-weight-normal mt-n2 ml-1",attrs:{"color":"green","prepend-icon":"mdi-piggy-bank"},model:{value:(_vm.check),callback:function ($$v) {_vm.check=$$v},expression:"check"}})],1),_c('v-col',{staticClass:"text-left mt-n1",attrs:{"cols":"9","sm":"9","md":"9","lg":"10","xl":"10"}},[(!!_vm.check)?_c('v-span',{staticClass:"subtitle-1 grey--text font-weight-light text-decoration-line-through",domProps:{"textContent":_vm._s(
            'Save an additional 1500 NZD/month in a dedicated WealthView account'
          )}}):_c('v-span',{staticClass:"subtitle-1 primary--text",domProps:{"textContent":_vm._s(
            'Save an additional 1500 NZD/month in a dedicated WealthView account'
          )}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"1","sm":"1","md":"1","lg":"1","xl":"1"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mt-n1 mr-4",attrs:{"color":"success"},on:{"click":function($event){return _vm.deleteConfirm(_vm.dataItem)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}])},[_c('span',[_vm._v("Delete")])])],1)],1),_c('v-divider',{staticClass:"mt-n3",attrs:{"color":"clientLayout"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }